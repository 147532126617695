import React, { PropsWithChildren, useCallback } from 'react';
import { PlaceholderExtension, wysiwygPreset } from 'remirror/extensions';
import { TableExtension } from '@remirror/extension-react-tables';
import { EditorComponent, Remirror, RemirrorProps, TableComponents, ThemeProvider, useRemirror } from '@remirror/react';
import { AllStyledComponent } from '@remirror/styles/emotion';

import { TopToolbar } from './top-toolbar';
import { BubbleMenu } from './bubble-menu';
import { CreateEditorStateProps, Extension, RemirrorEventListener } from 'remirror';

// import { ReactEditorProps } from '../types';

export interface ReactEditorProps extends Pick<CreateEditorStateProps, 'stringHandler'>, Pick<RemirrorProps, 'initialContent' | 'editable' | 'autoFocus' | 'hooks'> {
  placeholder?: string;
  onChange?: RemirrorEventListener<Extension>;
  content?: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface WysiwygEditorProps extends Partial<ReactEditorProps> {}

export function WysiwygEditor({ placeholder, stringHandler, content, children, ...rest }: PropsWithChildren<WysiwygEditorProps>) {
  const extensions = useCallback(() => [new PlaceholderExtension({ placeholder }), new TableExtension(), ...wysiwygPreset()], [placeholder]);

  const { manager } = useRemirror({ extensions, stringHandler, content });

  return (
    <AllStyledComponent>
      <Remirror manager={manager} {...rest}>
        <ThemeProvider>
          <TopToolbar />
          <EditorComponent />
          <BubbleMenu />
          <TableComponents />
        </ThemeProvider>
        {children}
      </Remirror>
    </AllStyledComponent>
  );
}
