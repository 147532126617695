import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Unstable_Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AlternateEmail from '@mui/icons-material/AlternateEmail';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Typography from '@mui/material/Typography';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import { styled } from '@mui/material/styles';

import AuthUser from '../components/AuthUser/AuthUser';
import { EllaMessage } from '../components/EllaMessage/EllaMessage';
import { AuthUserDetails } from '../components/AuthUser/AuthUser';

function AuthLogin() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = (event: { preventDefault: () => void; currentTarget: HTMLFormElement | undefined }) => {
    setLoading(true);
    event.preventDefault();
    try {
      const data = new FormData(event.currentTarget);
      const authUser = {
        email: data.get('email'),
        name: data.get('name'),
      } as AuthUserDetails;
      console.log('User login. User details: ', authUser);
      AuthUser.setAuthUser(authUser);
      navigate('/editor');
      setLoading(false);
      return navigate(0);
    } catch (error) {
      console.error('Error on login.', error);
      alert('Ocorreu um problema ao regitrar seu acesso. Tente novamente.');
      setLoading(false);
    }
  };

  return (
    <Grid container spacing={2} sx={{ pt: 2 }}>
      <Grid xs={12} display="flex" justifyContent="center">
        <EllaMessage>
          <p>Que bom ter você aqui! Sou a Ella, assistente virtual da Inboarding.</p>
          <p>Informe seu nome e e-mail para entrar.</p>
          <p>É rápido e você precisa informar apenas uma vez.</p>
        </EllaMessage>
      </Grid>

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box sx={{ marginTop: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '90%' }}>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <AccountCircle sx={{ color: 'action.active', mr: 1, my: 1 }} />
              <TextField variant="standard" margin="normal" required fullWidth id="name" label="Seu nome" name="name" autoComplete="name" />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <AlternateEmail sx={{ color: 'action.active', mr: 1, my: 1 }} />
              <TextField variant="standard" margin="normal" required fullWidth id="email" label="Seu e-mail" name="email" autoComplete="email" type="email" />
            </Box>
            <LoadingButton loading={loading} loadingPosition="start" type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Entrar
            </LoadingButton>
          </Box>
          <Box>
            <AuthHelpText />
          </Box>
        </Box>
      </Container>
    </Grid>
  );
}

function AuthHelpText() {
  const LightTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#FFF',
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      padding: 15,
    },
  }));

  return (
    <LightTooltip placement="top-start" title={<TextScoreHelpText />}>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
      <div tabIndex={0}>
        <Typography variant="body2">
          <HelpOutlineIcon sx={{ fontSize: 13, mr: 1 }} />
          Como seus dados são usados?
        </Typography>
      </div>
    </LightTooltip>
  );
}

function TextScoreHelpText(): JSX.Element {
  return (
    <div>
      <Typography variant="subtitle1">Como seus dados são usados?</Typography>
      <Typography variant="body2">Temos pessoas que utilizam o editor de forma incorreta.</Typography>
      <Typography variant="body2">Vamos usar seu nome e e-mail para contar seus acessos ao editor.</Typography>
      <Typography variant="body2">Com seus dados de acesso podemos manter a ferramenta segura.</Typography>
    </div>
  );
}

export default AuthLogin;
