import React from 'react';
import { WysiwygEditor } from './wysiwyg-editor';

interface EditorProps {
  children?: React.ReactNode | undefined;
  placeholder?: string;
  handleOnChange: (textHtml: string) => Promise<void>;
}

function Editor({ children, placeholder, handleOnChange }: EditorProps) {
  return (
    <div>
      <WysiwygEditor
        placeholder={placeholder}
        stringHandler="html"
        content=""
        onChange={(event) => {
          handleOnChange(event.helpers.getHTML());
        }}
      >
        {children}
      </WysiwygEditor>
    </div>
  );
}

export default Editor;
