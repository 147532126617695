import { AuthUserDetails } from '../components/AuthUser/AuthUser';
import { API } from './api';
import { AxiosError } from 'axios';

export const postTextAnalysis = async (text: string, authUser: AuthUserDetails) => {
  try {
    const payload = {
      data: text,
      authUser: authUser,
    };
    const { data, status } = await API.post('/text/analysis', payload);
    return { data, status };
  } catch (error) {
    console.log('Request error. ', JSON.stringify(error));
    const errorMessage: string = (error as AxiosError<any>)?.response?.data?.error?.message; // eslint-disable-line @typescript-eslint/no-explicit-any
    return { error, errorMessage };
  }
};
