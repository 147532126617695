import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material';

import './App.css';
import AppRoutes from './AppRoutes';
import LogoInboarding from './components/LogoInboarding';
import LogoCNPqMCTI from './components/LogoCNPqMCTI/LogoCNPqMCTI';
import FavoriteIcon from '@mui/icons-material/Favorite';

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#c4471a',
      },
      secondary: {
        main: '#4C2A82',
      },
    },
    typography: {
      fontFamily: '"PT Sans","Fira Sans","Droid Sans","Helvetica Neue",sans-serif',
      button: {
        textTransform: 'none',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <header className="App-header">
          <a href="https://www.inboarding.co/?utm=editor" target="_blank" title="Acessar site da Inboarding" rel="noreferrer">
            <LogoInboarding />
          </a>
        </header>
        <AppRoutes />
      </div>
      <div>
        <footer className="App-footer">
          <LogoCNPqMCTI />
          <p className="App-phrase-logo">
            Contamos com o apoio do CNPq e Ministério da Ciência, Tecnologia e Inovação (MCTI) para criar essa ferramenta e te entregar gratuitamente.
          </p>
          <p className="App-footer-inboarding">
            Feito com amor pela{' '}
            <a href="https://www.inboarding.co/?utm=editor" target="_blank" title="Acessar site da Inboarding" rel="noreferrer">
              Inboarding
            </a>
            .
            <FavoriteIcon fontSize="small" style={{ verticalAlign: 'bottom', fontSize: '1rem' }} />
          </p>
        </footer>
      </div>
    </ThemeProvider>
  );
}

export default App;
