import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import TextAnalysisEditor from './pages/TextAnalysisEditor';
import { AuthUserContext } from './components/AppContext/AppContext';
import AuthUser from './components/AuthUser/AuthUser';
import AuthLogin from './pages/AuthLogin';

function AppRoutes(): JSX.Element {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AppEditorMain />} />
        <Route path="/editor" element={<AppEditorMain />} />
      </Routes>
    </BrowserRouter>
  );
}

function AppEditorMain(): JSX.Element {
  const authUser = AuthUser.getAuthUser();

  if (!authUser) {
    return <AuthLogin />;
  }

  return (
    <AuthUserContext.Provider value={authUser}>
      <TextAnalysisEditor />
    </AuthUserContext.Provider>
  );
}

export default AppRoutes;
