import React from 'react';
import Editor from '../components/LogoInboarding/Editor/editor';
import { postTextAnalysis } from '../hooks/postTextAnalysis';

import { EllaMessage } from '../components/EllaMessage/EllaMessage';

import { styled } from '@mui/material/styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Grid from '@mui/material/Unstable_Grid2';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Accordion, AccordionDetails } from '@mui/material';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { Theme } from '@mui/system';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import CircularProgress from '@mui/material/CircularProgress';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import { debounce } from '../libs/debounce';
import { AuthUserContext } from '../components/AppContext/AppContext';

interface TextAnalysis {
  error: string;
  accessibility: Accessibility[];
  readability: Readability;
}

interface Accessibility {
  name: string;
  contents: string[];
}

interface Readability {
  score: number;
  totalWords: number;
  totalSentences: number;
  readingTime: number;
  totalCharacters: number;
}

function AccessilityTextAndLink({ items, goToAccessibilityList }: { items: Accessibility[] | undefined; goToAccessibilityList: () => void }): JSX.Element {
  if (items === undefined) {
    return <span>-</span>;
  }

  function handleClickReadAccessibility() {
    goToAccessibilityList();
  }

  const size = items.length;

  if (!size) {
    return <strong>{accessibilityItems(size)}</strong>;
  }

  return (
    <Link component="button" variant="body1" onClick={handleClickReadAccessibility} style={{ verticalAlign: 'baseline' }}>
      <strong>{accessibilityItems(size)}</strong>
    </Link>
  );
}

const accessibilityItems = (size: number) => {
  if (size === 0) {
    return `${size} itens, tudo certo.`;
  }

  if (size === 1) {
    return `${size} item para revisar.`;
  }

  return `${size} itens para revisar.`;
};

interface CompareTerm {
  wrong: string | JSX.Element;
  correct: string | JSX.Element;
}

const validationsBorderStyle = {
  boxShadow: '0px 0px 0px 2px #009688',
  borderRadius: '3px',
};

const formatReadTime = (seconds: number | undefined) => {
  if (seconds === undefined) {
    return '-';
  }

  if (seconds >= 60) {
    return `${Math.trunc(seconds / 60)} minutos e ${seconds % 60} segundos`;
  }

  return `${seconds} segundos`;
};

function TextAnalysisEditor(): JSX.Element {
  const [currentText, setCurrentText] = React.useState<string>('');
  const [changing, setChanging] = React.useState(false);
  const [sending, setSending] = React.useState(false);
  const [openValidated, setOpenValidated] = React.useState(false);
  const [textResult, setTextResult] = React.useState<TextAnalysis>();
  const [focusValidations, setFocusValidations] = React.useState(false);
  const accessibilityListReference = React.useRef<HTMLDivElement>(null);

  const authUserValue = React.useContext(AuthUserContext);

  function handleTextResult(result: TextAnalysis) {
    setTextResult(result);
    setFocusValidations(false);
  }

  const handleValidedClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenValidated(false);
  };

  function goToAccessibilityList() {
    scrollToElement(accessibilityListReference);
  }

  function scrollToElement(element: React.RefObject<HTMLElement>): void {
    setFocusValidations(true);
    element?.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }

  async function handleSendText(textHtml: string) {
    const { data, status, error, errorMessage } = await postTextAnalysis(textHtml, authUserValue);
    const HttpSuccessStatus = 200;
    if (status === HttpSuccessStatus) {
      handleTextResult(data.data);
      setOpenValidated(true);
    } else {
      handleTextResult({ error: 'Erro: ' + (errorMessage || error) } as TextAnalysis);
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const debouncedSendRequest = React.useCallback(
    debounce(async (textHtml: string) => {
      if (textHtml !== '' && textHtml !== '<p></p>' && currentText !== textHtml) {
        setSending(true);
        handleSendText(textHtml).then(() => {
          setChanging(false);
          setSending(false);
        });
        setOpenValidated(true);
        setCurrentText(textHtml);
      }
      setChanging(false);
    }, 1500),
    [] // será criada apenas uma vez inicialmente
  );

  const buildChangingSavingMessages = (): JSX.Element => {
    if (!currentText || currentText === '<p></p>') {
      return <>Digite ou cole o texto no editor para validar.</>;
    }
    if (sending || changing) {
      return (
        <>
          <CircularProgress size="1rem" style={{ verticalAlign: 'text-top' }} /> Estou analisando seu novo texto.
        </>
      );
    }

    if (currentText) {
      return (
        <>
          <TaskAltIcon fontSize="small" style={{ fontSize: '1rem', verticalAlign: 'text-top', color: 'green' }} /> Pronto! Já validei.
        </>
      );
    }

    return <></>;
  };

  async function handleOnChange(textHtml: string) {
    if (textHtml && textHtml !== '<p></p>') {
      setChanging(true);
      setOpenValidated(true);
      debouncedSendRequest(textHtml);
    }
  }

  return (
    <Grid container spacing={2} sx={{ pt: 2 }}>
      <Grid xs={12} display="flex" justifyContent="center">
        <EllaIntroMessage />
      </Grid>
      <Grid md={8} xs={12} sx={{ mt: 3 }} order={{ md: 1, xs: 2 }}>
        <div>
          <Editor placeholder="Escreva seu texto aqui..." handleOnChange={handleOnChange}>
            <div ref={accessibilityListReference} style={Object.assign({ marginTop: '15px' }, focusValidations ? validationsBorderStyle : {})}>
              <TextAnalysisResults textResult={textResult} />
            </div>
          </Editor>
        </div>
      </Grid>
      <Grid md={4} xs={12} order={{ md: 2, xs: 1 }}>
        <TextStatsResults textResult={textResult} goToAccessibilityList={goToAccessibilityList} textProcessing={buildChangingSavingMessages} />
        <Snackbar open={openValidated} autoHideDuration={6000} onClose={handleValidedClose}>
          <MuiAlert elevation={6} variant="filled" severity={sending || changing ? 'info' : 'success'} onClose={handleValidedClose} sx={{ width: '100%' }}>
            {sending || changing ? 'Estou analisando seu novo texto.' : 'Pronto! Já validei.'}
          </MuiAlert>
        </Snackbar>
      </Grid>
    </Grid>
  );

  function EllaIntroMessage() {
    const authUserValue = React.useContext(AuthUserContext);
    const firtName = authUserValue?.name ? authUserValue?.name.split(' ')[0] : '';

    return (
      <EllaMessage>
        <p>{firtName ? `Olá, ${firtName}. ` : ''}Que bom ter você aqui! Sou a Ella, assistente virtual da Inboarding.</p>
        <p>Vou te ajudar a criar um texto mais fácil e acessível pra todo mundo.</p>
        <p>Confira o resultado em tempo real pra saber se o texto precisa de ajustes!</p>
      </EllaMessage>
    );
  }
}

function TextStatsResults({
  textResult,
  goToAccessibilityList,
  textProcessing,
}: {
  textResult: TextAnalysis | undefined;
  goToAccessibilityList: () => void;
  textProcessing: () => JSX.Element;
}): JSX.Element {
  function buildReadLevel(readScore: number | undefined): string {
    if (readScore === undefined) {
      return '-';
    }

    if (readScore >= 80) {
      return 'pessoas com ensino do 1° ao 5° ano.';
    }

    if (readScore >= 60) {
      return 'pessoas com ensino do 6° ao 9° ano.';
    }

    if (readScore >= 40) {
      return 'pessoas com ensino médio.';
    }

    if (readScore >= 20) {
      return 'pessoas com ensino médio ou técnico.';
    }

    return 'pessoas com ensino superior.';
  }

  return (
    <p>
      <Typography variant="h6" component="h2">
        {buildTitle(textResult?.readability?.score)}
      </Typography>
      <Typography variant="body2">{textProcessing()}</Typography>
      <TextScoreDetails readScore={textResult?.readability?.score} />
      <Typography variant="body1">
        Nível de leitura: <strong>{buildReadLevel(textResult?.readability?.score)}</strong>
      </Typography>
      <Typography variant="body1">
        Acessibilidade: <AccessilityTextAndLink items={textResult?.accessibility} goToAccessibilityList={goToAccessibilityList} />
      </Typography>
      <Typography variant="body1">
        Número de caracteres: <strong>{!textResult?.readability?.totalCharacters ? '-' : textResult.readability.totalCharacters}</strong>
      </Typography>
      <Typography variant="body1">
        Número de palavras: <strong>{!textResult?.readability?.totalWords ? '-' : textResult.readability.totalWords}</strong>
      </Typography>
      <Typography variant="body1">
        Número de frases: <strong>{!textResult?.readability?.totalSentences ? '-' : textResult.readability.totalSentences}</strong>
      </Typography>
      <Typography variant="body1">
        Tempo de leitura: <strong>{formatReadTime(textResult?.readability?.readingTime)}</strong>
      </Typography>
      <Typography variant="body1" style={{ paddingTop: '10px' }}>
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a href="https://blog.inboarding.co/editor-inboarding-pro/" target="_blank">
          Conheça a <strong>versão PRO</strong>.
        </a>{' '}
        ⚙️
      </Typography>
    </p>
  );
}

function buildTitle(readScore: number | undefined): string {
  if (readScore === undefined) {
    return 'Nível de leitura do seu texto.';
  }

  if (readScore >= 80) {
    return 'Seu texto está muito fácil.';
  }

  if (readScore >= 60) {
    return 'Seu texto está fácil.';
  }

  if (readScore >= 40) {
    return 'Seu texto está mediano.';
  }

  if (readScore >= 20) {
    return 'Seu texto está difícil.';
  }

  return 'Seu texto está muito difícil.';
}

function TextScoreDetails({ readScore }: { readScore: number | undefined }): JSX.Element {
  const LightTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#FFF',
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      padding: 15,
    },
  }));

  function buildColorFromScore(readScore: number | undefined, theme: Theme): string {
    if (readScore === undefined) {
      return theme.palette.grey[200];
    }

    if (readScore >= 80) {
      return theme.palette.success.main;
    }

    if (readScore >= 60) {
      return theme.palette.success.light;
    }

    if (readScore >= 40) {
      return theme.palette.warning.light;
    }

    if (readScore >= 20) {
      return theme.palette.error.light;
    }

    return theme.palette.error.main;
  }

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 20,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[200],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: buildColorFromScore(readScore, theme),
    },
  }));

  const progressScore = readScore === 0 ? 1 : readScore;

  return (
    <LightTooltip placement="top-start" title={<TextScoreHelpText />}>
      <div style={{ padding: '15px 0' }}>
        <Typography variant="h5" component="h3">
          {readScore !== undefined && readScore >= 0 ? readScore.toFixed(0) : '-'} / 100
          <IconButton size="small" style={{ verticalAlign: 'super' }}>
            <HelpOutlineIcon sx={{ fontSize: 12 }} />
          </IconButton>
        </Typography>
        <BorderLinearProgress variant="determinate" value={progressScore} />
      </div>
    </LightTooltip>
  );
}

function TextScoreHelpText(): JSX.Element {
  return (
    <div>
      <Typography variant="subtitle1">Nota do Teste de Leitura</Typography>
      <Typography variant="body2">Para testar o nível de leitura de um texto, nós utilizamos o Teste de Legibilidade de Flesch-Kincaid.</Typography>
      <Typography variant="body2">A nota é calculada com o tamanho da frase e o tamanho das palavras: quanto maior, mais difícil de ler.</Typography>
      <Typography variant="body2">A versão original tem precisão de quase 90% - o que, para uso comum, é um valor muito bom.</Typography>
    </div>
  );
}

function TextAnalysisResults({ textResult }: { textResult: TextAnalysis | undefined }): JSX.Element {
  if (!textResult) {
    return <p></p>;
  }

  if (textResult.error) {
    return <p>{textResult.error}</p>;
  }

  if (!textResult.accessibility?.length) {
    return <p></p>;
  }

  return (
    <>
      {textResult.accessibility.map((item) => {
        switch (item.name) {
          case 'complexWords':
            return <TextAnalysisComplexWords contents={item.contents} />;
          case 'bold':
            return <TextAnalysisBold contents={item.contents} />;
          case 'emojis':
            return <TextAnalysisEmojis contents={item.contents} />;
          case 'uppercase':
            return <TextAnalysisUppercase contents={item.contents} />;
          case 'startsWithLowercase':
            return <TextAnalysisStartsWithLowercase contents={item.contents} />;
          case 'sentencesWithoutPronouns':
            return <TextAnalysisSentencesWithoutPronouns contents={item.contents} />;
          case 'italic':
            return <TextAnalysisItalic contents={item.contents} />;
          case 'underlined':
            return <TextAnalysisUnderlined contents={item.contents} />;
          case 'emoticon':
            return <TextAnalysisEmoticon contents={item.contents} />;
          case 'longSentences':
            return <TextAnalysisLongSentences contents={item.contents} />;
          case 'idiomaticExpressions':
            return <TextAnalysisIdiomaticExpressions contents={item.contents} />;
          case 'capacitism':
            return <TextAnalysisCapacitism contents={item.contents} />;
          case 'racism':
            return <TextAnalysisRacism contents={item.contents} />;
          case 'symbolNeutralGender':
            return <TextAnalysisSymbolNeutralGender contents={item.contents} />;
          case 'neutralGenderLanguage':
            return <TextAnalysisNeutralGenderLanguage contents={item.contents} />;
          case 'linksWithoutContext':
            return <TextAnalysisLinkWithoutContext contents={item.contents} />;
          case 'futureTenseVerbs':
            return <TextAnalysisFutureTenseVerbs contents={item.contents} />;
        }
      })}
    </>
  );
}

function TableCompareTerms({ title, terms }: { title: string; terms: CompareTerm[] }): JSX.Element {
  return (
    <div style={{ paddingTop: '15px' }}>
      <Typography style={{ paddingBottom: '10px' }}>{title}</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <Typography variant="subtitle1">Faça assim. ✅</Typography>
              </TableCell>
              <TableCell style={{ borderLeft: '1px solid rgb(224, 224, 224)' }} align="left">
                <Typography variant="subtitle1">Evite fazer. ❌</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {terms.map((term: CompareTerm, index: number) => (
              <TableRow key={index}>
                <TableCell align="left">{term.correct}</TableCell>
                <TableCell style={{ borderLeft: '1px solid rgb(224, 224, 224)' }} align="left">
                  {term.wrong}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

function TextAnalysisValidation({ title, children }: { title: string; children: JSX.Element }): JSX.Element {
  const AccordionSummary = styled((props: AccordionSummaryProps) => <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />)(
    ({ theme }) => ({
      flexDirection: 'row-reverse',
      '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
      },
      '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
      },
      '& .MuiAccordionSummary-content.Mui-expanded': {
        marginLeft: theme.spacing(1),
      },
    })
  );

  const contentId = Math.random().toString(36).slice(-6);
  return (
    <Accordion>
      <AccordionSummary aria-controls={`validation-content-${contentId}`} id={`validation-${contentId}`}>
        <Typography variant="subtitle1">
          <strong>{title}</strong>
          <Link component="button" variant="body2" style={{ marginLeft: '5px', verticalAlign: 'baseline' }}>
            Ler exemplos
          </Link>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {children}
        <Typography variant="subtitle1" style={{ paddingTop: '25px' }}>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a href="https://blog.inboarding.co/editor-inboarding-pro/" target="_blank">
            Na <strong>versão PRO</strong> você pode configurar suas regras.
          </a>{' '}
          ⚙️
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}

//Início das funções de validação dos itens de acessibilidade

function SynonymsSuggestionSentence({ contents }: { contents: string[] }): JSX.Element {
  const firstWord = contents[0];
  const synonymsLink = `https://www.google.com/search?q=sinônimo+%22${firstWord}%22`;
  const trendsLink = `https://trends.google.com.br/trends/explore?q=${firstWord}&geo=BR`;

  return (
    <Typography style={{ paddingTop: '15px' }}>
      Você pode{' '}
      <a href={synonymsLink} target="_blank" rel="noreferrer">
        pesquisar por sinônimos
      </a>{' '}
      e comparar esses resultados no{' '}
      <a href={trendsLink} target="_blank" rel="noreferrer">
        Google Trends
      </a>{' '}
      para escolher a palavra mais buscada e comum.
    </Typography>
  );
}

function TextAnalysisComplexWords({ contents }: { contents: string[] }): JSX.Element {
  const terms = [
    {
      wrong: 'Consequentemente o treinamento será adiado.',
      correct: 'Por esse motivo o curso vai ser adiado.',
    },
  ];

  const wordsOrderedLength = contents.sort((a: string, b: string) => b.length - a.length);

  return (
    <TextAnalysisValidation title="Palavras longas podem ser difíceis de entender.">
      <>
        <Typography>
          Você usou {wordsOrderedLength.length > 1 ? 'as palavras' : 'a palavra'}: <strong>{wordsOrderedLength.join(', ')}</strong>.
        </Typography>
        <div style={{ paddingTop: '15px' }}>
          <Typography>Palavras longas deixam o texto mais difícil. Escolha palavras mais simples e casuais para melhorar o nível de leitura do texto.</Typography>
        </div>
        <SynonymsSuggestionSentence contents={contents} />
        <TableCompareTerms terms={terms} title="Exemplos sobre palavras difíceis:" />
      </>
    </TextAnalysisValidation>
  );
}

function TextAnalysisSymbolNeutralGender({ contents }: { contents: string[] }): JSX.Element {
  const terms = [
    {
      wrong: 'Bem-vind@. Vamos conversar com todes!',
      correct: 'Que bom ter você aqui. Vamos conversar com todo mundo.',
    },
  ];

  const wordsOrderedLength = contents.sort((a: string, b: string) => b.length - a.length);

  return (
    <TextAnalysisValidation title="Evite neutralizar o texto com ‘@’ ‘x’ ou ‘e’.">
      <>
        <Typography>
          Você usou {wordsOrderedLength.length > 1 ? 'as palavras' : 'a palavra'}: <strong>{wordsOrderedLength.join(', ')}</strong>.
        </Typography>
        <div style={{ paddingTop: '15px' }}>
          <Typography>
            Escrever sem flexionar gênero torna o conteúdo mais inclusivo pra pessoas diversas, mas é importante ser acessível também. Quando usamos ‘@’ ou outros elementos
            estranhos prejudicamos a experiência de pessoas que usam leitor de tela, pessoas neurodivergentes e outras. O que você pode fazer é explorar o português pra neutralizar
            o texto.
          </Typography>
        </div>
        <TableCompareTerms terms={terms} title="Exemplos sobre texto neutro e acessível:" />
      </>
    </TextAnalysisValidation>
  );
}

function TextAnalysisNeutralGenderLanguage({ contents }: { contents: string[] }): JSX.Element {
  const terms = [
    {
      wrong: 'Os chefes são tranquilos.',
      correct: 'A chefia é tranquila.',
    },
    {
      wrong: 'O lider do setor.',
      correct: 'A liderança do setor.',
    },
    {
      wrong: 'Todos os funcionários da Empresa.',
      correct: 'Todas as pessoas que trabalham na Empresa.',
    },
  ];

  const wordsOrderedLength = contents.sort((a: string, b: string) => b.length - a.length);

  return (
    <TextAnalysisValidation title="Tente escrever de forma neutra">
      <>
        <Typography>
          Você usou {wordsOrderedLength.length > 1 ? 'as palavras' : 'a palavra'}: <strong>{wordsOrderedLength.join(', ')}</strong>.
        </Typography>
        <div style={{ paddingTop: '15px' }}>
          <Typography>
            Flexionar sempre o gênero no texto não é inclusivo. Existem algumas formas de escrever sem demarcar o gênero masculino ou feminino. Claro que nem sempre é possível
            tornar o texto total neutro, mas com os recursos do português isso fica mais fácil.
          </Typography>
        </div>
        <TableCompareTerms terms={terms} title="Exemplos sobre texto neutro e acessível:" />
      </>
    </TextAnalysisValidation>
  );
}

function TextAnalysisEmojis({ contents }: { contents: string[] }): JSX.Element {
  const terms = [
    {
      wrong: 'Eba! 🤩 Seu primeiro dia é amanhã',
      correct: 'Eba! Seu primeiro dia é amanhã. 🤩',
    },
  ];

  const wordsOrderedLength = contents.sort((a: string, b: string) => b.length - a.length);

  return (
    <TextAnalysisValidation title="Use emojis apenas no fim da frase! 😉">
      <>
        <Typography>
          Você usou {wordsOrderedLength.length > 1 ? 'os emojis' : 'o emoji'}: <strong>{wordsOrderedLength.join(', ')}</strong>.
        </Typography>
        <div style={{ paddingTop: '15px' }}>
          <Typography>
            O conteúdo fica confuso pra pessoas cegas quando você coloca um emoji no meio da frase. Isso acontece porque o leitor de tela lê o texto alternativo do emoji.
          </Typography>
        </div>
        <TableCompareTerms terms={terms} title="Exemplos de como usar emojis:" />
      </>
    </TextAnalysisValidation>
  );
}

function TextAnalysisUppercase({ contents }: { contents: string[] }): JSX.Element {
  const terms = [
    {
      wrong: 'BAIXE O MATERIAL AQUI',
      correct: 'Baixe o material aqui',
    },
  ];

  const wordsOrderedLength = contents.sort((a: string, b: string) => b.length - a.length);

  return (
    <TextAnalysisValidation title="Evite escrever frases ou palavras todas em maiúsculo.">
      <>
        <Typography>
          Você usou {wordsOrderedLength.length > 1 ? 'as palavras' : 'a palavra'}: <strong>{wordsOrderedLength.join(', ')}</strong>.
        </Typography>
        <div style={{ paddingTop: '15px' }}>
          <Typography>Isso prejudica a leitura de pessoas com dislexia. O melhor é deixar apenas a letra inicial em maiúsculo, menos no caso de siglas.</Typography>
        </div>
        <TableCompareTerms terms={terms} title="Exemplos de como usar letras maiúsculas:" />
      </>
    </TextAnalysisValidation>
  );
}

function TextAnalysisStartsWithLowercase({ contents }: { contents: string[] }): JSX.Element {
  const terms = [
    {
      wrong: 'convite pra reunião.',
      correct: 'Convite pra reunião.',
    },
  ];

  const wordsOrderedLength = contents.sort((a: string, b: string) => b.length - a.length);

  return (
    <TextAnalysisValidation title="Comece frases com letra maiúscula. ">
      <>
        <Typography>
          Você usou {wordsOrderedLength.length > 1 ? 'as frases' : 'a frase'}: <strong>{wordsOrderedLength.join(', ')}</strong>.
        </Typography>
        <div style={{ paddingTop: '15px' }}>
          <Typography>
            Começar o texto com letra minúscula é ruim pra quem usa leitor de tela, como pessoas cegas ou com baixa visão. Também não é bom pra pessoas neurodivergentes.
          </Typography>
        </div>
        <TableCompareTerms terms={terms} title="Exemplos de como iniciar uma frase:" />
      </>
    </TextAnalysisValidation>
  );
}

function TextAnalysisEmoticon({ contents }: { contents: string[] }): JSX.Element {
  const terms = [
    {
      wrong: 'Parabéns, você foi a pessoa aprovada. :)',
      correct: 'Parabéns, você foi a pessoa aprovada. 🙂',
    },
  ];

  const wordsOrderedLength = contents.sort((a: string, b: string) => b.length - a.length);

  return (
    <TextAnalysisValidation title="Use emojis “🙂” no lugar de emoticons “:)”.">
      <>
        <Typography>
          Você usou {wordsOrderedLength.length > 1 ? 'os emoticons' : 'o emoticon'}: <strong>{wordsOrderedLength.join(', ')}</strong>.
        </Typography>
        <div style={{ paddingTop: '15px' }}>
          <Typography>
            Diferente dos emoticons, todo emoji tem um texto alternativo que pode ser lido pelo leitor de tela, o que ajuda pessoas cegas a entenderem o conteúdo.
          </Typography>
        </div>
        <TableCompareTerms terms={terms} title="Exemplos sobre uso de emoticons:" />
      </>
    </TextAnalysisValidation>
  );
}

function TextAnalysisLongSentences({ contents }: { contents: string[] }): JSX.Element {
  const terms = [
    {
      wrong: 'Seu primeiro dia na empresa será divulgado em breve para que você possa começar na empresa da melhor forma possível.',
      correct: 'Vamos divulgar a data do seu início na empresa em breve.',
    },
  ];

  const wordsOrderedLength = contents.sort((a: string, b: string) => b.length - a.length);

  return (
    <TextAnalysisValidation title="Escreva frases curtas.">
      <>
        <Typography>
          Você usou {wordsOrderedLength.length > 1 ? 'as sentenças' : 'a sentença'}:
          <ol>
            {wordsOrderedLength.map((text: string, index: number) => (
              <li key={index}>{text}.</li>
            ))}
          </ol>
        </Typography>
        <div style={{ paddingTop: '15px' }}>
          <Typography>
            Textos muito longos prejudicam o entendimento de pessoas surdas, autistas, disléxicas e com baixa escolaridade. Busque escrever frases simples e curtas.
          </Typography>
        </div>
        <TableCompareTerms terms={terms} title="Exemplos de como escrever frases curtas:" />
      </>
    </TextAnalysisValidation>
  );
}

function TextAnalysisItalic({ contents }: { contents: string[] }): JSX.Element {
  const terms = [
    {
      wrong: <i>Leia esse material antes de começar.</i>,
      correct: 'É importante ler esse material antes de começar.',
    },
  ];

  const wordsOrderedLength = contents.sort((a: string, b: string) => b.length - a.length);

  return (
    <TextAnalysisValidation title="Evite usar itálico.">
      <>
        <Typography>
          Você usou {wordsOrderedLength.length > 1 ? 'as palavras em itálico' : 'a palavra em itálico'}: <strong>{wordsOrderedLength.join(', ')}</strong>.
        </Typography>
        <div style={{ paddingTop: '15px' }}>
          <Typography>Usar itálico nos textos prejudica o entendimento de pessoas com dislexia.</Typography>
        </div>
        <TableCompareTerms terms={terms} title="Exemplos sobre uso do itálico:" />
      </>
    </TextAnalysisValidation>
  );
}

function TextAnalysisUnderlined({ contents }: { contents: string[] }): JSX.Element {
  const terms = [
    {
      wrong: <u>Tenha atenção com as normas de segurança.</u>,
      correct: 'Tenha atenção com as normas de segurança.',
    },
  ];

  const wordsOrderedLength = contents.sort((a: string, b: string) => b.length - a.length);

  return (
    <TextAnalysisValidation title="Evite sublinhar toda a frase.">
      <>
        <Typography>
          Você usou sublinhado {wordsOrderedLength.length > 1 ? 'nas palavras' : 'na palavra'}: <strong>{wordsOrderedLength.join(', ')}</strong>.
        </Typography>
        <div style={{ paddingTop: '15px' }}>
          <Typography>Sublinhar textos prejudica o entendimento de pessoas com dislexia. O uso é indicado apenas em hiperlinks (links dentro de palavras ou frases). </Typography>
        </div>
        <TableCompareTerms terms={terms} title="Exemplos sobre o uso do sublinhado:" />
      </>
    </TextAnalysisValidation>
  );
}

function TextAnalysisBold({ contents }: { contents: string[] }): JSX.Element {
  const terms = [
    {
      wrong: (
        <>
          <b>Complete</b> o treinamento de <b>segurança</b> até <b>samanhã.</b>
        </>
      ),
      correct: (
        <>
          Complete o treinamento de segurança <b>até amanhã</b>.
        </>
      ),
    },
  ];

  const wordsOrderedLength = contents.sort((a: string, b: string) => b.length - a.length);
  return (
    <TextAnalysisValidation title="Use negrito, mas sem excesso.">
      <>
        <Typography>
          Você usou {wordsOrderedLength.length > 1 ? 'as palavras em negrito' : 'a palavra em negrito'}: <strong>{wordsOrderedLength.join(', ')}</strong>.
        </Typography>
        <div style={{ paddingTop: '15px' }}>
          <Typography>
            Usar o negrito ajuda a destacar palavras importantes, mas se você usar em excesso o efeito é contrário. Além disso, esse uso torna o texto confuso pra pessoas com
            dislexia.
          </Typography>
        </div>
        <TableCompareTerms terms={terms} title="Exemplos de como usar o negrito:" />
      </>
    </TextAnalysisValidation>
  );
}

function TextAnalysisIdiomaticExpressions({ contents }: { contents: string[] }): JSX.Element {
  const terms = [
    {
      wrong: 'Comece o seu dia com o pé direito.',
      correct: 'Comece o seu dia bem.',
    },
  ];

  const wordsOrderedLength = contents.sort((a: string, b: string) => b.length - a.length);

  return (
    <TextAnalysisValidation title="Cuidado com expressões idiomáticas. ">
      <>
        <Typography>
          Você usou {wordsOrderedLength.length > 1 ? 'as expressões idiomáticas' : 'a expressão idiomática'}: <strong>{wordsOrderedLength.join(', ')}</strong>.
        </Typography>
        <div style={{ paddingTop: '15px' }}>
          <Typography>
            Expressões como ‘começar com o pé direito’ afetam a acessibilidade do seu conteúdo. Pessoas surdas, autistas e de outros grupos entendem essas expressões de forma
            literal. Se você diz que é pra começar com o pé direito, elas podem entender que precisam andar iniciando com esse pé.
          </Typography>
        </div>
        <SynonymsSuggestionSentence contents={contents} />
        <TableCompareTerms terms={terms} title="Exemplos sobre expressões idiomáticas:" />
      </>
    </TextAnalysisValidation>
  );
}

function TextAnalysisCapacitism({ contents }: { contents: string[] }): JSX.Element {
  const terms = [
    {
      wrong: 'Ele é um joão sem braço.',
      correct: 'Ele sempre foge das obrigações.',
    },
  ];

  const wordsOrderedLength = contents.sort((a: string, b: string) => b.length - a.length);

  return (
    <TextAnalysisValidation title="Evite palavras capacitistas.">
      <>
        <Typography>
          Você usou {wordsOrderedLength.length > 1 ? 'as expressões capacitistas' : 'a expressão capacitista'}: <strong>{wordsOrderedLength.join(', ')}</strong>.
        </Typography>
        <div style={{ paddingTop: '15px' }}>
          <Typography>
            Falar que alguém é mudinho, que é um ‘joão sem braço’ ou que está ‘cego de raiva’ são exemplos que devem ser evitados pra tornar seu conteúdo mais inclusivo.
          </Typography>
        </div>
        <SynonymsSuggestionSentence contents={contents} />
        <TableCompareTerms terms={terms} title="Exemplos sobre palavras capacitistas:" />
      </>
    </TextAnalysisValidation>
  );
}

function TextAnalysisRacism({ contents }: { contents: string[] }): JSX.Element {
  const terms = [
    {
      wrong: 'Ele quer denegrir meu trabalho.',
      correct: 'Ele quer prejudicar meu trabalho.',
    },
  ];

  const wordsOrderedLength = contents.sort((a: string, b: string) => b.length - a.length);

  return (
    <TextAnalysisValidation title="Atenção com palavras racistas.">
      <>
        <Typography>
          Você usou {wordsOrderedLength.length > 1 ? 'as expressões racistas' : 'a expressão racista'}: <strong>{wordsOrderedLength.join(', ')}</strong>.
        </Typography>
        <div style={{ paddingTop: '15px' }}>
          <Typography>
            O racismo está em tudo, inclusive na nossa fala. Repensar as palavras que você usa é importante pra tornar seu conteúdo mais inclusivo. Usar ‘inveja branca’ pra se
            referir a algo positivo e usar denegrir como algo negativo são exemplos de expressões racistas.
          </Typography>
        </div>
        <SynonymsSuggestionSentence contents={contents} />
        <TableCompareTerms terms={terms} title="Exemplos sobre palavras racistas:" />
      </>
    </TextAnalysisValidation>
  );
}

function TextAnalysisSentencesWithoutPronouns({ contents }: { contents: string[] }): JSX.Element {
  const terms = [
    {
      wrong: 'Resolva as pendências ainda hoje.',
      correct: 'Líder, você precisa resolver suas pendências hoje.',
    },
  ];

  const wordsOrderedLength = contents.sort((a: string, b: string) => b.length - a.length);

  return (
    <TextAnalysisValidation title="Use pronome no texto.">
      <>
        <Typography>
          Você usou {wordsOrderedLength.length > 1 ? 'as sentenças sem pronome' : 'a sentença sem pronome'}: <strong>{wordsOrderedLength.join(', ')}</strong>.
        </Typography>
        <div style={{ paddingTop: '15px' }}>
          <Typography>É muito importante deixar claro com quem você está falando porque pessoas surdas podem ter dificuldade com textos que não usam a palavra “você”.</Typography>
        </div>
        <TableCompareTerms terms={terms} title="Exemplos sobre uso do pronome:" />
      </>
    </TextAnalysisValidation>
  );
}

function TextAnalysisLinkWithoutContext({ contents }: { contents: string[] }): JSX.Element {
  const linksTerms = [
    {
      wrong: 'Clique aqui.',
      correct: 'Clique aqui para conferir o manual.',
    },
  ];

  const butonsTerms = [
    {
      wrong: 'Saber mais',
      correct: 'Conferir manual',
    },
  ];

  const wordsOrderedLength = contents.sort((a: string, b: string) => b.length - a.length);

  return (
    <TextAnalysisValidation title="Evite escrever botões e links como 'clique aqui' e 'saiba mais'.">
      <>
        <Typography>
          Você usou {wordsOrderedLength.length > 1 ? 'os links' : 'o link'}: <strong>{wordsOrderedLength.join(', ')}</strong>.
        </Typography>
        <div style={{ paddingTop: '15px' }}>
          <Typography>
            Pessoas que usam leitor de tela são prejudicadas quando você escreve botões genéricos como &apos;saiba mais&apos;. Ao invés disso, você pode falar sobre o que acontece
            se ela clicar no link ou botão.
          </Typography>
        </div>
        <TableCompareTerms terms={linksTerms} title="Exemplos sobre como escrever links:" />
        <Typography style={{ paddingTop: '15px' }}>No caso de botões, a aparência já é clicável, então não precisa usar o &apos;clique aqui&apos;.</Typography>
        <TableCompareTerms terms={butonsTerms} title="Exemplos sobre como escrever botões:" />
      </>
    </TextAnalysisValidation>
  );
}

function TextAnalysisFutureTenseVerbs({ contents }: { contents: string[] }): JSX.Element {
  const linksTerms = [
    {
      wrong: 'Você ganhará pontos.',
      correct: 'Você vai ganhar pontos.',
    },
  ];

  const wordsOrderedLength = contents.sort((a: string, b: string) => b.length - a.length);

  return (
    <TextAnalysisValidation title="Tenha atenção ao conjugar verbos no futuro.">
      <>
        <Typography>
          Você usou {wordsOrderedLength.length > 1 ? 'os verbos no futuro' : 'o verbo no futuro'}: <strong>{wordsOrderedLength.join(', ')}</strong>.
        </Typography>
        <div style={{ paddingTop: '15px' }}>
          <Typography>
            Na Libras não se conjuga verbos como no português. Nem para o futuro e nem para o passado. Para tornar seu texto mais simples e claro para pessoas surdas, você pode
            adicionar &apos;vai&apos; ou &apos;foi&apos; que são verbos mais simples e que se assemelham a uma tradução livre.
          </Typography>
        </div>
        <TableCompareTerms terms={linksTerms} title="Exemplos de como escrever frases sem verbos no futuro:" />
      </>
    </TextAnalysisValidation>
  );
}
//Fim das funções de validação dos itens de acessibilidade

export default TextAnalysisEditor;
