import React, { ReactNode } from 'react';

import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';

import ella from './ella.svg';

interface IEllaMessageProps {
  children: ReactNode;
}

export const EllaMessage = ({ children }: IEllaMessageProps) => {
  return (
    <Paper elevation={1} sx={{ backgroundColor: '#E8F1F8', borderRadius: 4 }}>
      <div style={{ display: 'flex', alignItems: 'center', margin: '10px', padding: '15px 5px' }}>
        <Avatar alt={'Ella'} src={ella} sx={{ width: '3.5em', height: '3.5em' }} style={{ marginRight: '5px' }} />
        <div style={{ marginLeft: '0.5rem' }}>{children}</div>
      </div>
    </Paper>
  );
};
