import localStore from '../LocalStore/LocalStore';
import LogRocket from 'logrocket';

export interface AuthUserDetails {
  email: string;
  name: string;
}

class AuthUser {
  isAuthenticated(): boolean {
    return !!localStore.get('login');
  }

  logout(): void {
    localStore.remove('login');
  }

  setAuthUser(authUser: AuthUserDetails): void {
    localStore.set('authUser', authUser ? JSON.stringify(authUser) : undefined);
    this.setSessionMonitorUser(authUser);
  }

  getAuthUser(): AuthUserDetails {
    const authUser = localStore.get('authUser');
    return authUser ? JSON.parse(authUser) : undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setSessionMonitorUser(user: any): void {
    if (user?.email) {
      // LogRocket error sessions monitoring
      try {
        LogRocket.identify(user.email, {
          name: user.name,
          email: user.email,
        });
      } catch (error) {
        console.log('[Warning] not sending session monitoring data to LogRocket.');
      }
    }
  }
}

export default new AuthUser();
